
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { emptyToLine } from "@/utils/common";
import { Table } from "@/utils/decorator";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
const base = namespace("base");
const supplier = namespace("supplier");
const purchase = namespace("purchase");
const coupon = namespace("coupon");

@Component({})
@Table("loadListData")
export default class CouponSend extends Vue {
  @base.Action getDictionaryList;
  @purchase.Action getPurchaseList;
  @coupon.Action queryCustomerCouponList;
  @coupon.Action queryList;
  @coupon.Action manualVerification;
  emptyToLine = emptyToLine;
  dayjs = dayjs;
  supplierList: any = [];
  orderStatusList: any[] = [];
  settlementList: any = [];
  orderList: any[] = [];
  couponTypeList: any = [];
  activityStatusList: any = [];
  searchForm = {
    customerName: "",
    customerMobile: "",
    activityName: "",
    couponType: "",
    couponStatus: ""
  };
  couponList: any[] = [];
  //计算属性
  get searchList() {
    return [
      {
        label: this.$t("finance.guest-names"),
        type: "input",
        value: "",
        prop: "customerName"
      },
      {
        label: this.$t("v210801.contact-number"),
        type: "input",
        value: "",
        prop: "customerMobile"
      },
      {
        label: this.$t("v210831.coupon-name"),
        type: "select-search",
        value: "",
        prop: "activityCode",
        placeholder: this.$t("v210831.enter-coupon-keyword"),
        selectList: this.couponList,
        searchFun: this.searchSupplier
      },
      {
        label: "marketing.coupon-type",
        type: "select",
        value: "",
        selectList: this.couponTypeList,
        prop: "couponType"
      },
      {
        label: "finance.state",
        type: "select",
        value: "",
        selectList: this.activityStatusList,
        prop: "couponStatus"
      }
    ];
  }
  searchSupplier(val = "") {
    this.queryList({
      pageNum: 1,
      pageSize: 10,
      activityName: val
    }).then(data => {
      this.couponList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.activityName;
            item.value = item.activityCode;
            return item;
          })
        : [];
    });
  }
  created() {
    this.init();
  }
  mounted() {}
  init() {
    this.getDictionaryList("coupon_type").then(data => {
      this.couponTypeList = data;
    });
    this.getDictionaryList("coupon_status").then(data => {
      this.activityStatusList = data;
    });
    this.loadListData();
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryCustomerCouponList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.orderList = data.data.list;
      return data;
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  handleView(row) {
    let { href } = this.$router.resolve({
      path: `/purchase/purchase-view/${row.purchaseNo}`
    });
    window.open(href, "_blank");
  }
  handleActivation(row) {
    const { couponCode, activityCode} = row;
    this.$confirm("确定要失效吗", t("v210831.tips"), {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    })
      .then(() => {
        this.manualVerification({ couponCode,activityCode }).then(res => {
          this.$message.success("手工失效成功");
          this.loadListData();
        });
      })
      .catch(() => {});
  }

  handleUserView(row) {
    let { href } = this.$router.resolve({
      path: `/customer/customer-view/${row.customerMobile}`
    });
    window.open(href, "_blank");
  }
}
